<template>
    <div class="common-form-layout">
        <global-page-back class="title" v-if="comArr.includes(comp)" :detailPageTitle="formName" @handlerGlobalType="handlerGlobalType">
            <template #globalPageBackTabs>
                <div class="tabs-list">
                    <div
                        v-for="(item, index) of tabList"
                        :key="index"
                        :class="['tabs-item', {current: item.sel}]"
                        @click="processComp(index)"
                    >
                        {{ item.title }}
                    </div>
                </div>
            </template>
        </global-page-back>
        <div class="process-content">
            <component
                v-bind:is="comp"
                ref="comp"
                @changeComp="changeComp"
                :id="id"
                :formType="formType"
                :teacherId="teacherId"
                :teacherName="teacherName"
                :formObj = "formObj"
                :formName="formName"
                :fillStatus="fillStatus"
                :releaseStatus="releaseStatus"
                :userPopulation="userPopulation"
            ></component>
        </div>
    </div>
</template>
<script>
import GlobalPageBack from "./Sub/GlobalPageBack/index.vue";
import TabbedPage from "@/components/scrollWrapper/Sub/TabbedPage/index.vue";
import OverallResult from './InterconnectionInfoCollection/OverallResults.vue';
import OverallResultsDetails from './InterconnectionInfoCollection/OverallResultsDetails.vue';
import UserFillingStatus from './InterconnectionInfoCollection/UserFillingStatus.vue';
import EvaluationOfTeacherStatistics from './InterconnectionInfoCollection/EvaluationOfTeacherStatistics.vue';
import EvaluateTeacherDetails from './InterconnectionInfoCollection/EvaluateTeacherDetails.vue';
import RecordsList from '@/libs/pageListOptimal.js';
import {OverallResults} from "@/models/OverallResults.js";
export default {
    name: "InterconnectionInfoCollectionStatostics",
    props: ["formData","formType","formName","fillStatus","releaseStatus"],
    computed: {
        detailPageTitle() {
            return '统计'
        },
    },
    components: {
        GlobalPageBack,
        TabbedPage,
        OverallResult,
        OverallResultsDetails,
        UserFillingStatus,
        EvaluationOfTeacherStatistics,
        EvaluateTeacherDetails
    },
    data(){
        return {
            comArr:[
                'OverallResult',
                'UserFillingStatus',
                'EvaluationOfTeacherStatistics'
            ],
            formObj:{},
            tabList: [
                {
                    id: "0",
                    title: "整体结果",
                    sel: true,
                    newsType: "15",
                    comp: 'OverallResult'
                },
                {
                    id: "1",
                    title: "用户填写情况",
                    sel: false,
                    newsType: "15",
                    comp: 'UserFillingStatus'
                },

            ],
            comp:'OverallResult',
            id:'',
            teacherId:'',
            teacherName:'',
            userPopulation: ""
        }
    },
    created(){
        this.id = this.formData;
        this.getTotal();
    },
    mounted() {
        // 1学生评价教师 2 学生评价班主任 0 其他
        if(this.formType == '1'){
            this.tabList.push({
                id: "1",
                title: "评价教师统计",
                sel: false,
                comp: 'EvaluationOfTeacherStatistics'
            })
        } else if(this.formType == '2'){
            this.tabList.push({
                id: "2",
                title: "评价班主任统计",
                sel: false,
                comp: 'EvaluationOfTeacherStatistics'
            })
        }

    },
    methods: {
        getTotal() {
            let modeApi = new OverallResults();
            modeApi.getCount(this.id).then((res) => {
                if (res.data.code == 200) {
                        this.userPopulation = res.data.data.userPopulation;
                } else {
                    this.$message({
                        message: res.data.msg,
                        type: 'error'
                    })
                }
            });
        },
        changeComp(val){
            this.comp = val.comp
            if(val.teacherId){
                this.teacherId = val.teacherId
            } else {
                this.teacherId = ''
            }
            if(val.teacherName){
                this.teacherName = val.teacherName
            } else {
                this.teacherName = ''
            }

            if(val.formObj){
                this.formObj = val.formObj
            }
        },
        handlerGlobalType() {
            this.$emit('changeComp',{
                type: 'list',
                component: 'InterconnectionInfoCollection',
                data: ''
            })
        },
        changeTabs(data) {
            this.tabList.forEach((item) => {
                this.$set(item, "sel", item.id === data.id);
            });
            // this.listQuery.newsType = data.newsType
            // this.getList(1)
        },
        processComp (index) {
            this.index = index;
            this.tabList.forEach((ite, idx) => {
                if (index == idx) {
                    ite.sel = true;
                } else {
                    ite.sel = false;
                }
            })
            this.comp = this.tabList[index].comp
            //this.getBasicInformationData()
        },
    }
}
</script>
<style lang="scss" scoped>
.tabs-list {
        flex: 1;
        justify-content: center;
        position: relative;
        display: flex;
        height: 48px;
        line-height: 48px;
        margin-right: 190px;

        .tabs-item {
            width: 130px;
            position: relative;
            text-align: center;
            font-size: 15px;
            color: #606C80;
            cursor: pointer;
        }

        .tabs-item.current {
            color: #3C7FFF;
            font-weight: 600;
            &:after {
                content: "";
                width: 0;
                height: 0;
                border-left: 7px solid transparent;
                border-right: 7px solid transparent;
                border-bottom: 8px solid #3c7fff;
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translate(calc(-50% ));
            }
        }
    }
    .title{
        margin-bottom: 10px;
    }
    .process-content {
        border-radius: 4px;

        margin: 0px 0px 10px 0;
        height: calc(100vh - 90px);
        overflow: auto;
    }
</style>
